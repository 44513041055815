import React from 'react'
import Componcontact from '../../components/Contact/contact-compon';
import Banner from '../../components/Bannersection/banner';
import project from './project.json';
import { AiOutlineArrowRight } from 'react-icons/ai'
import {Helmet} from "react-helmet";

function Projects() {

  const pagename = [
    {
      pagetitle: "Our Projects",
      subtitle: "Our Projects",
    }
  ]
  return (
    <div>
      <Helmet>
    <title>Our Projects - DGi WORX - Showcase of Innovative IT Solutions</title>
    <meta name="description" content="Explore a curated collection of projects by DGi WORX, highlighting our expertise in delivering innovative IT solutions. Discover our successful implementations and the impact of our technology projects." />
    <meta name="keywords" content="Dgiworx, myirmedic, e-visamalaysia, sprsolutionsinc, lakshanassociates, auswidemedicalrecruitment, lagomcommunity, currybox, capitalwisegroup, wise group, our projects, IT solutions, dgiworx, technology projects, innovation, project showcase, successful implementations, project impact, DGi WORX projects, dgiworx" />
    <meta property="og:title" content="Our Projects - DGi WORX - Showcase of Innovative IT Solutions" />
    <meta property="og:description" content="Explore a curated collection of projects by DGi WORX, highlighting our expertise in delivering innovative IT solutions. Discover our successful implementations and the impact of our technology projects." />
    <meta property="og:image" content="/assets/img/dgiworx-home-embedded.jpg"/>
    <meta property="og:url" content="https://dgiworx.in/projects" />
    
    {/* Add more meta tags as needed */}
</Helmet>

      {/* <!-- breadcrumb-area-start --> */}
      <Banner items={pagename} />

      {/* <!-- breadcrumb-area-end --> */}

      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="tp-about-two-wrap mb-30 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-about-two-thumb">
                  <div className='tp-about-circle'>
                    <div className='tp-about-circle-two'>
                    <img src="assets/img/project/project_about-1.jpg" alt="" loading='lazy' />
                    </div>
                  </div>
                  
                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img src="assets/img/project/project_about-2.jpg" alt="" loading='lazy' />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img src="assets/img/about/two/dote.png" alt="" loading='lazy' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-two-wrapper mb-30 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                <div className="tp-section mb-40">
                  <span className="tp-section-sub-title">Exemplifying Our Expertise</span>
                  <h2 className="tp-section-title">Showcasing Our Mastery<br /></h2>
                  <div className="tp-section-title-wrapper">
                    <p>Welcome to the heart of our creativity and expertise. At DGi WORX, we take immense pride in the projects we've undertaken and successfully delivered. Each
                      project is a testament to our dedication, innovation, and commitment to excellence. Browse through our diverse portfolio, spanning web development, app
                      development, programming, graphics design, and more. Immerse yourself in the visual narratives and technological marvels we've created for our clients.<br /><br />
                      From captivating websites that leave a lasting impression to dynamic mobile apps that redefine user experiences, our projects showcase the depth of our skills and
                      the breadth of our capabilities.
                    </p>
                  </div>
                </div>
                <div className="tp-about-two-list">
                  <ul>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Diverse Portfolio</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Creative Excellence</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Client Success</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Technological Expertise</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Innovation and Ingenuity</li>
                    <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Inspiration Source</li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/about">Discover More</a>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img src="assets/img/about/two/about-2-shape-2.png" alt="" loading='lazy' />
          </div>
        </div>

      </section>
      {/* <!-- about-area-end --> */}

      {/* Lates projects section */}
      <section className=' py-50'>
        <div className='container'>


          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">Explore Our Projects</span>
                <h2 className="tp-section-title">Our Recent Completed Projects</h2>
                <p>DGi WORX has been helping technology startups, global brands, and Fortune companies create impactful software solutions.
                  These are the projects that we have recently completed.</p>
              </div>
            </div>
          </div>
          <div className="row">

            {
              project.map(project => {
                return (
                  <div className="col-lg-6 col-md-12" key={project.id}>
                    <div className="tp-project-3-item mb-30 tp-thumb-common fix wow fadeInRight" data-wow-duration="1.2s"
                      data-wow-delay=".6s">
                      <div className="tp-project-3-thumb">
                        <div className="tp-thumb-common-overlay wow"></div>
                        <img src={project.image} alt={project.title} loading="lazy" />
                      </div>
                      <div className="dgi-project-3-info d-flex justify-content-between align-items-center">
                        <div className="tp-project-3-content">
                          <span>{project.category}</span>
                          <h4 className="tp-project-3-title under-line-white">{project.title}</h4>
                          <p>{project.description}</p>
                        </div>
                        <div className="tp-project-3-icon">
                          <a href={project.url} target='_blank'><i><AiOutlineArrowRight className="fa-regular fa-arrow-right" /></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                );
              })
              
            }
          </div>
          <br/>
        </div>
      </section>

      {/* ---contact section ---*/}
      {/* <Componcontact /> */}
      {/* ---contact section end --*/}
    </div>
  )
}

export default Projects;