import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../../components/Bannersection/banner';

// Function to generate a slug from a title
const generateSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '') // Remove invalid chars
    .replace(/\s+/g, '-')        // Collapse whitespace and replace by -
    .replace(/-+/g, '-');        // Collapse dashes
};

const decodeHtml = (html) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

const pagename = [
  {
    pagetitle: "Blogs",
    subtitle: "Blogs",
  }
];

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 6;

  useEffect(() => {
    // Fetch blog data from the WordPress API
    fetch('https://blogs.dgiworx.in/wp-json/wp/v2/posts')
      .then((response) => response.json())
      .then(async (data) => {
        // Fetch category name and featured image for each blog post
        const blogsWithCategoryAndImage = await Promise.all(
          data.map(async (blog) => {
            let updatedBlog = { ...blog };
            updatedBlog.slug = generateSlug(decodeHtml(blog.title.rendered)); // Add slug to blog

            if (blog.categories.length > 0) {
              // Fetch category name
              const categoryResponse = await fetch(blog._links['wp:term'][0].href);
              const categoryData = await categoryResponse.json();
              const categoryName = categoryData[0].name;
              updatedBlog.category_name = categoryName;
            }
            if (blog.featured_media !== 0) {
              // Fetch featured image URL
              const mediaResponse = await fetch(blog._links['wp:featuredmedia'][0].href);
              const mediaData = await mediaResponse.json();
              const mediaUrl = mediaData.guid.rendered;
              updatedBlog.featured_media_url = mediaUrl;
            }
            return updatedBlog;
          })
        );
        setBlogs(blogsWithCategoryAndImage);
      })
      .catch((error) => console.error('Error fetching blogs:', error));
  }, []);
    // Paginate the blogs array
     const startIndex = (currentPage - 1) * blogsPerPage;
      const endIndex = startIndex + blogsPerPage;
      const paginatedBlogs = blogs.slice(startIndex, endIndex);

      const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
      };
  return (
    <main>
      <Banner items={pagename} />
      <section className="blog-area tp-blog-inner pt-120 pb-120">
        <div className="container">
          <div className="row">
            {paginatedBlogs.map((blog) => (
              <div key={blog.id} className="col-lg-4 col-md-6">
                <div className="tp-blog-two mb-30">
                  <div className="tp-blog-two-item">
                    <div className="tp-blog-two-thumb overlay-anim tp-thumb-common fix">
                      <div className="tp-thumb-common-overlay wow"></div>
                      <Link to={`/blog/${blog.slug}`}>
                        <img src={blog.featured_media_url} alt={decodeHtml(blog.title.rendered)} />
                        <div className="tp-blog-two-thumb-plus">
                          <i className="fa-regular fa-plus"></i>
                        </div>
                      </Link>
                    </div>
                    <div className="tp-blog-two-content">
                      <span>{new Date(blog.date).toLocaleDateString()} - {blog.category_name}</span>
                      <h4 className="tp-blog-two-title">
                        {decodeHtml(blog.title.rendered)}
                      </h4>
                      <p>
                        {blog.excerpt.rendered.replace(/<[^>]+>/g, '').slice(0, 200)}
                        {blog.excerpt.rendered.replace(/<[^>]+>/g, '').length > 200 && '...'}
                      </p>
                      <div className="tp-blog-two-btn">
                        <Link to={`/blog/${blog.slug}`}>Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* Pagination */}
          <div className="row">
            <div className="col">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  {Array.from({ length: Math.ceil(blogs.length / blogsPerPage) }, (_, i) => (
                    <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                      <button className="page-link" onClick={() => handlePageChange(i + 1)}>{i + 1}</button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BlogList;
