import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsBehance } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn, FaXTwitter } from "react-icons/fa6";
import { TfiYoutube } from "react-icons/tfi";

const Sidebar = () => {
  const [latestPosts, setLatestPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [postDates, setPostDates] = useState([]);

  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  useEffect(() => {
    // Fetch latest posts
    fetch('https://blogs.dgiworx.in/wp-json/wp/v2/posts?per_page=3')
      .then(response => response.json())
      .then(async (posts) => {
        // Fetch featured image URL for each post
        const updatedPosts = await Promise.all(
          posts.map(async (post) => {
            if (post.featured_media !== 0) {
              const mediaResponse = await fetch(post._links['wp:featuredmedia'][0].href);
              const mediaData = await mediaResponse.json();
              const mediaUrl = mediaData.guid.rendered;
              return { ...post, featured_media_url: mediaUrl };
            } else {
              return { ...post, featured_media_url: 'default-image-url' };
            }
          })
        );
        setLatestPosts(updatedPosts);
      })
      .catch(error => console.error('Error fetching latest posts:', error));

    // Fetch categories
    fetch('https://blogs.dgiworx.in/wp-json/wp/v2/categories')
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => console.error('Error fetching categories:', error));

    // Fetch tags
    fetch('https://blogs.dgiworx.in/wp-json/wp/v2/tags')
      .then(response => response.json())
      .then(data => setTags(data))
      .catch(error => console.error('Error fetching tags:', error));

    // Fetch post dates
    fetch('https://blogs.dgiworx.in/wp-json/wp/v2/posts?per_page=3')
      .then(response => response.json())
      .then(posts => {
        const dates = posts.map(post => post.date);
        setPostDates(dates);
      })
      .catch(error => console.error('Error fetching post dates:', error));
  }, []);

  return (
    <div className="sidebar-wrapper mb-60">
      <div className="sidebar-widget sidebar-widget-2 mb-30">
        <h3 className="sidebar-widget-title">Our Latest Post</h3>
        <div className="sidebar-widget-content">
          <div className="sidebar-post rc-post">
            {latestPosts.map((post, index) => (
              <div key={post.id} className="rc-post mb-20 d-flex align-items-center">
                <div className="rc-post-thumb">
                  <Link to={`/blog/${post.slug}`}>
                    <img src={post.featured_media_url} alt={decodeHtml(post.title.rendered)} />
                  </Link>
                </div>
                <div className="rc-post-content">
                  <h3 className="rc-post-title">
                    <Link to={`/blog/${post.slug}`}>{decodeHtml(post.title.rendered)}</Link><br></br>
                  </h3>
                  <span>{new Date(postDates[index]).toLocaleDateString()}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="sidebar-widget sidebar-widget-2 mb-30">
        <h3 className="sidebar-widget-title">Categories</h3>
        <div className="sidebar-widget-Catagory">
          {categories.map(category => (
            <p key={category.id}>{category.name}</p>
          ))}
        </div>
      </div>
      
      <div className="sidebar-widget sidebar-widget-2 mb-30">
        <h3 className="sidebar-widget-title">Our Tag</h3>
        <div className="sidebar-widget-content">
          <div className="tagcloud">
            {tags.map(tag => (
              <span key={tag.id}>{tag.name}</span>
            ))}
          </div>
        </div>
      </div>

      <div className="sidebar-widget sidebar-widget-2 mb-30">
        <h3 className="sidebar-widget-title">Social Links</h3>
        <div className="sidebar-widget-social">
          <a href="https://www.linkedin.com/company/67087770/" target="_blank"><i><FaLinkedinIn className="fa-brands fa-linkedin-in"/></i></a>
          <a href="https://www.facebook.com/dgiworx" target="_blank"><i><FaFacebookF className="fa-brands fa-facebook-f"/></i></a>
          <a href="https://www.instagram.com/dgiworx/" target="_blank"><i><AiFillInstagram className="fa-brands fa-instagram"/></i></a>
          <a href="https://www.behance.net/dgiworx" target="_blank"><i><BsBehance className="fa-brands fa-behance"/></i></a>
          <a href="https://www.twitter.com/dgiworx" target="_blank"><i><FaXTwitter className="fa-brands fa-twitter"/></i></a>
          <a href="https://www.youtube.com/channel/UCxN2y-6L8tL_ZNXYPq8_n3g" target="_blank"><i><TfiYoutube  className="fa-brands fa-youtube-f"/></i></a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
